import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AWSSDK from 'aws-sdk';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Environment } from '../../config/environment';
import { AppUtil } from '../../utils/app.util';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { Config } from '../../config/config';
import { DataCarrierService } from './data-carrier.service';
import { Router } from '@angular/router';
import { UTMParameters } from '../../beans/auth/utm-parameters.bean';
import { UtmParams } from '../../modules/scheduling/types.scheduling';
import { JwtHelper } from './jwt-helper.service';
import { APP_USER_PERMISSIONS, PERMISSON_ALLOWED_KEY } from 'src/app/constants/app-user-permissions.constants';
import { NgxPermissionsService } from 'ngx-permissions';

/** AWS Object */
declare let AWS: any;
/** Cognito pool data object */
let PoolData = {
	ClientId: '',
	UserPoolId: '',
	IdentityPoolId: '',
};
/** hold user pool object */
let userPool;
/** cognito login URL*/
let loginUrl;

/**
 * Cognito related authentication services
 */
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	/** Observable returning the status cognito operations */
	result: Subject<any>;

	/**
	 * Auth Service constructor
	 * @param environment - Environemnt Config
	 * @param config - URL Config
	 * @param dataCarrier - Data Carrier Service
	 * @param router - Angular Router
	 */
	constructor(
		private environment: Environment,
		private config: Config,
		private dataCarrier: DataCarrierService,
		private router: Router,
		private readonly jwtHelper: JwtHelper,
		private readonly permissionService: NgxPermissionsService
	) {
		const env: any = this.environment.getEnvironment();
		/** constricting cognito pool data using cognito credentials */
		PoolData = {
			ClientId: env.CLIENT_ID,
			UserPoolId: env.USER_POOL_ID,
			IdentityPoolId: env.IDENTITY_POOL_ID,
		};
		loginUrl = env.LOGIN_URL;
		userPool = new CognitoUserPool(PoolData);
	}

	/**
	 * Getter method for IDToken
	 */
	getToken(): string {
		let jwtToken = null;
		if (sessionStorage[this.config.TOKEN_NAME]) {
			const authToken = JSON.parse(sessionStorage[this.config.TOKEN_NAME]);
			jwtToken = authToken.idToken.jwtToken;
		}
		return jwtToken;
	}

	loadAppLevelPermissions() {
		const applicationPermissionsForLoggedInUser = this.getAppPermissionsForLoggedInUser();
		if (applicationPermissionsForLoggedInUser) {
			applicationPermissionsForLoggedInUser.forEach(permission => {
				this.permissionService.addPermission(permission);
			});
		}
		// console.log('permissions available are ', this.permissionService.getPermissions());
	}

	getAppPermissionsForLoggedInUser() {
		const decodedToken = this.jwtHelper.getDecodedToken();
		if (decodedToken[PERMISSON_ALLOWED_KEY]) {
			const permissionsStringArray = decodedToken ? decodedToken[PERMISSON_ALLOWED_KEY].split(',') : null;
			if (permissionsStringArray) {
				// parse string into enumerables thus only allowing permissions which are mutually decided both on FE and BE
				const permissions = permissionsStringArray
					.map((permission: string) => {
						if (permission in APP_USER_PERMISSIONS) {
							return APP_USER_PERMISSIONS[permission];
						}
						return null;
					})
					.filter(p => p);
				return permissions;
			}
		}
		return null;
	}

	async hasPermission(permission: string): Promise<boolean> {
		const permissions = this.permissionService.getPermissions();
		return !!permissions[permission];
	}

	/**
	 * Getter method for Token expiry date
	 * @param token IDToken
	 */
	getTokenExpirationDate(token: string): Date {
		const decoded = jwtDecode<JwtPayload>(token);
		if (decoded.exp === undefined) {
			return null;
		}
		const date = new Date(decoded.exp);
		return date;
	}

	/**
	 * checking whether the given token expired or not
	 * by checking expiry timestamp of token
	 * @param token - IDToken
	 * return true or false
	 */
	isTokenExpired(token?: string): boolean {
		if (!token) {
			token = this.getToken();
		}
		if (!token) {
			return true;
		}
		const date = this.getTokenExpirationDate(token);
		if (date === undefined) {
			return false;
		}
		// Rounding off current timestamp to 10 digits
		const currentTimestamp = Math.floor(new Date().valueOf() / 1000);
		return !(date.valueOf() > currentTimestamp);
	}

	/**
	 * Cognito Sign up
	 * @param object - contain user related information like email and password
	 * return the response using Observable
	 */
	signUpUser(object) {
		this.result = new Subject<any>();
		userPool.signUp(AppUtil.getFormattedEmail(object.email), object.password, null, null, (err, res) => {
			if (err) {
				console.error(`[${AuthService.name}][${this.signUpUser.name}]`, 'Error during user signup', object.email, err);
				const error = { success: false, object: err };
				this.result.error(error);
			} else {
				const error = { success: true, object: '' };
				this.result.next(error);
			}
		});
	}

	/**
	 * Cognito Login
	 * @param username - user email Id
	 * @param password - user password
	 */
	login(username: string, password: string) {
		const authData = {
			Username: username,
			Password: password,
		};
		const authDetails = new AuthenticationDetails(authData);
		const userData = {
			Username: username,
			Pool: userPool,
		};

		/**
		 * clear local storage, session storage and  AWS Credentials
		 * before new session begins
		 */
		// Getting utm params which is set before login, and setting back to local storgae
		// Utm params is required in book appointments - google analytics
		const utm = localStorage.getItem('utm');
		const forgotPassToaster = sessionStorage.getItem('forgotPassToaster');
		const createAccountToaster = sessionStorage.getItem('createAccountToaster');
		localStorage.clear();
		sessionStorage.clear();
		// Setting back the params in local storage
		localStorage.setItem('utm', utm);
		sessionStorage.setItem('forgotPassToaster', forgotPassToaster);
		sessionStorage.setItem('createAccountToaster', createAccountToaster);
		if (AWS.config.credentials) {
			AWS.config.credentials.clearCachedId();
		}
		const cognitoUser = new CognitoUser(userData);
		this.result = new Subject<any>();

		cognitoUser.authenticateUser(authDetails, {
			onSuccess: result => {
				sessionStorage.setItem(this.config.TOKEN_NAME, JSON.stringify(result));
				sessionStorage.setItem('userId', result['idToken']['payload'].sub);

				// FIX FITEHEME-986
				this.getAwsCredentials();

				this.result.next(result);
			},
			onFailure: err => {
				console.error(`[${AuthService.name}][${this.login.name}]`, 'Error on login auth', userData, err);
				// Forcing reset password, when email is changed on epms side
				if (err.code === this.config.exceptions.cognito.RESET_REQUIRED) {
					this.router.navigate(['/reset/password'], {
						queryParams: { email: btoa(username), resetPassword: true },
					});
				} else {
					if (err.code === 'InvalidParameterException') {
						err.message = 'Invalid Parameters in Email or Password';
					}
					this.result.error(err);
				}
			},
		});
	}

	/**
	 * Retrieve AWS credentials of current user
	 */
	getAwsCredentials() {
		const cognitoGetUser = userPool.getCurrentUser();
		if (cognitoGetUser != null) {
			const _self = this;
			cognitoGetUser.getSession(function (err, result) {
				AWSSDK.config.region = 'us-east-1';
				if (result) {
					const Logins = {};
					Logins[_self.environment.getEnvironment().LOGINS_URL] = result.getIdToken().getJwtToken();
					AWSSDK.config.update({
						credentials: new AWSSDK.CognitoIdentityCredentials({
							IdentityPoolId: PoolData.IdentityPoolId,
							Logins: Logins,
						}),
					});
				}
			});
		}
	}

	isLoggedIn() {
		return userPool.getCurrentUser();
	}

	getUnauthCredentials() {
		return new Promise((resolve, rej) => {
			const _self = this;
			AWSSDK.config.update({
				region: _self.config.cognitoConfig.region,
				credentials: new AWSSDK.CognitoIdentityCredentials({
					IdentityPoolId: PoolData.IdentityPoolId,
				}),
			});
			resolve(AWSSDK.config.credentials);
		});
	}

	getAwsUnauthCredentials() {
		const _self = this;
		AWSSDK.config.update({
			region: _self.config.cognitoConfig.region,
			credentials: new AWSSDK.CognitoIdentityCredentials({
				IdentityPoolId: _self.config.cognitoConfig.unAuthIdentityPoolId,
			}),
		});
	}

	/**
	 * Logs out current user
	 * clears local storage and session storage
	 */
	logoutUser() {
		if (userPool.getCurrentUser() !== null) {
			userPool.getCurrentUser().signOut();
		}
		if (AWS.config.credentials && !localStorage.getItem('sso-type')) {
			AWS.config.credentials.clearCachedId();
			AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
		}
		if (window[this.config.userLoginSession.sessionCheckIntervalVarName]) {
			clearInterval(window[this.config.userLoginSession.sessionCheckIntervalVarName]);
		}
		localStorage.clear();
		sessionStorage.clear();
		this.dataCarrier.reset();
	}

	/**
	 * Cognito forgot password
	 * @param email
	 */
	forgotPassword(email, sendLink: boolean = false, returnUrl: string = '') {
		const userData = {
			Username: email,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		this.result = new Subject<any>();
		let clientMetadata = {};
		const utmParams = this.getUtmParameters();
		if (sendLink && utmParams) {
			clientMetadata = {
				sendLink: sendLink.toString(),
				returnUrl: returnUrl,
				...utmParams,
			};
		} else {
			clientMetadata = { sendLink: sendLink.toString(), returnUrl: returnUrl };
		}
		cognitoUser.forgotPassword(
			{
				onSuccess: result => {
					const error = { success: true, object: result };
					this.result.next(error);
				},
				onFailure: err => {
					console.error(`[${AuthService.name}][${this.forgotPassword.name}]`, 'Error on forgot password', email, err);
					const error = { success: false, object: err };
					this.result.error(error);
				},
			},
			clientMetadata
		);
	}

	/**
	 * Cognito reset password
	 * @param email
	 */
	resetPassword(userEmailID, verificationCode, newPassword) {
		const userData = {
			Username: userEmailID,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		this.result = new Subject<any>();
		cognitoUser.confirmPassword(verificationCode, newPassword, {
			onSuccess: () => {
				const error = { success: true };
				this.result.next(error);
			},
			onFailure: (err: Error) => {
				console.error(`[${AuthService.name}][${this.resetPassword.name}]`, 'Error on reset password', userEmailID, err);
				const error = { success: false, object: err };
				this.result.error(error);
			},
		});
	}

	getResult(): Observable<any> {
		return this.result.asObservable();
	}

	/**
	 * Cognito update user password
	 * @param email
	 */
	updateUserPassword(oldPassword, newPassword) {
		const cognitoUser = userPool.getCurrentUser();
		if (cognitoUser != null) {
			// To get the session of current user
			const thisRef = this;
			cognitoUser.getSession(function (err, session) {
				if (err) {
					console.error(
						`[${AuthService.name}][${thisRef.updateUserPassword.name}][1]`,
						'Error updating user password',
						err
					);
				}
			});
		}
		this.result = new Subject<any>();
		cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
			if (err) {
				console.error(`[${AuthService.name}][${this.updateUserPassword.name}][2]`, 'Error updating user password', err);
				const error = { success: false, object: err };
				this.result.error(error);
			} else {
				const error = { success: true, object: '' };
				this.result.next(error);
			}
		});
	}

	/**
	 * Set AWS Credential for auto login using IdToken getting from SSO
	 * @param authToken - Token from SSO
	 */
	setAWSCredentials(authToken: any) {
		AWSSDK.config.region = this.config.cognitoConfig.region;
		const Logins = {};
		Logins[this.environment.getEnvironment().LOGINS_URL] = authToken.idToken.jwtToken;
		AWSSDK.config.credentials = new AWSSDK.CognitoIdentityCredentials({
			IdentityPoolId: PoolData.IdentityPoolId,
			Logins,
		});
	}

	/**
	 * Sets utm params in local storage
	 * @param params Utm Params
	 */
	setUtmParameters(params: any) {
		const utm = new UTMParameters();
		if (params.utm_source || params.utm_medium || params.utm_term || params.utm_content || params.utm_campaign) {
			utm.utmSource = params.utm_source;
			utm.utmMedium = params.utm_medium;
			utm.utmTerm = params.utm_term;
			utm.utmContent = params.utm_content;
			utm.utmCampaign = params.utm_campaign;
			localStorage.setItem('utm', JSON.stringify(utm));
		}
	}

	/**
	 * Gets Utm params object from local storage
	 */
	getUtmParameters() {
		if (localStorage.getItem('utm')) {
			const params = JSON.parse(localStorage.getItem('utm'));
			if (params) {
				const utmParams = new UtmParams();
				utmParams.utm_campaign = params.utmCampaign;
				utmParams.utm_content = params.utmContent;
				utmParams.utm_medium = params.utmMedium;
				utmParams.utm_source = params.utmSource;
				utmParams.utm_term = params.utmTerm;
				return utmParams;
			}
		}
	}
}
