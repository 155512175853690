<app-ehe-header [auth]="true" [wellness]="true"></app-ehe-header>

<div class="vaccine-records-container">
	<div class="container">
		<div class="row">
			<div class="col-md-9 make-center">
				<ng-container *ngIf="vaxpassEnabled">
					<div class="vax-pass" *ngIf="!isFullyVaccinated">
						<div class="content">
							<div class="vaccine-conf">
								<div class="left-content">
									<ng-container>
										<p>
											<span [translate]="'vax-pass.banner.description'"
												>EHE Health makes it easy to show your COVID-19 vaccination status. Get your COVID-19 vaccine at
												EHE Health or retrieve an existing vaccination record.
											</span>
											<span
												class="faq"
												(click)="showFaq()"
												[translate]="'vax-pass.banner.faq'"
												appGoogleAnalytics
												[eventData]="{
													eventCategory: 'Link',
													eventAction: 'Click - FAQ Link',
													eventLabel: 'VaxStatus - More about EHE Vaccine Passport'
												}">
												More about EHE Vaccine Passport.</span
											>
										</p>
										<div *ngIf="showGetMyVaccineBtn">
											<button
												class="button"
												mat-button
												(click)="hideGetMyVaccineButton()"
												appEheAnalytics
												[data]="analyticsData"
												appGoogleAnalytics
												[eventData]="{
													eventCategory: 'Button',
													eventAction: 'Click - Get My Vaccine Passport',
													eventLabel: 'VaxStatus - Get My Vaccine Passport'
												}">
												<span [translate]="'vax-pass.banner.get-vax-pass-btn'"></span>
											</button>
										</div>
									</ng-container>
								</div>
								<div class="right-content">
									<img src="/assets/images/VaxStatusLogo.svg" alt="VAXPASS" />
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div *ngIf="showRadioButtons">
										<p [translate]="'vax-pass.banner.vaccine-confirmation'">
											<b>Have you fully received your COVID-19 vaccination?</b>
											<br /><span [translate]="'vax-pass.banner.covid-vaccine-description'"
												>(1 dose for Johnson & Johnson, 2 doses for for Pfizer and Moderna)</span
											>
										</p>
										<mat-radio-group class="radio-buttons" [formControl]="vaccineConfirmation">
											<mat-radio-button class="p-r-40" value="Yes" (change)="getVaccineConfirmation($event)">
												<span [translate]="'vax-pass.banner.yes-btn'">Yes</span>
											</mat-radio-button>
											<mat-radio-button class="p-r-40" value="No" (change)="getVaccineConfirmation($event)">
												<span [translate]="'vax-pass.banner.no-btn'">No</span>
											</mat-radio-button>
										</mat-radio-group>
									</div>
									<div class="redirect-text" *ngIf="vaccineConfirmation.value === 'No' && this.isChecked">
										<span [translate]="'vax-pass.banner.not-vaccinated-content'"
											>Please come back to this page once you have completed your COVID-19 vaccine.
										</span>
										<span class="link" [translate]="'vax-pass.banner.book-covid-vaccine'">
											<a
												href=""
												[routerLink]="['/scheduling/create/appointment']"
												[queryParams]="{ procedure: 'Inoculation' }"
												appEheAnalytics
												[data]="{
													pageUrl: '/vaxstatus',
													redirectedTo: '/scheduling/create/appointment',
													componentName: 'vax pass',
													id: 'Book Inoculation link',
													placement: 'vax pass'
												}">
												Click here to book your COVID-19 vaccine at EHE Health!</a
											></span
										>
									</div>
									<div *ngIf="(vaccineConfirmation.value === 'Yes' && this.isChecked) || step2">
										<app-vaxpass-consent-form (cancel)="onCancel()"></app-vaxpass-consent-form>
									</div>
								</div>
							</div>
							<div class="partial-vaccination" *ngIf="isPartiallyVaccinated">
								<div class="row">
									<div class="col-md-12">
										<h4 [translate]="'vax-pass.partial-vaccination.heading'">Partial COVID-19 Vaccination</h4>
										<p [translate]="'vax-pass.partial-vaccination.content'">
											Your passport will be available after you have received your second dose of the COVID-19 vaccine.
											Please come back at that time and try again. If you believe this is an error, please contact
											<span [translate]="'vax-pass.member-services'"
												><a class="link" href="mailto:MemberServices@ehe.health"
													>MemberServices&#64;ehe.health.</a
												></span
											>
										</p>
										<div>
											<button
												class="button"
												appEheAnalytics
												[data]="analyticsData"
												mat-button
												(click)="refreshProfile()">
												Try Again
											</button>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="loader" class="loader">
								<i class="fas fa-spinner fa-pulse"></i>
								<p [translate]="'vax-pass.loading'">Pulling up your vaccine record. Hang tight!</p>
							</div>
							<div *ngIf="checkBackError" class="errorMsg" [class.mt-4]="showGetMyVaccineBtn">
								<div>
									{{ errorMessage }}
									<span [translate]="'vax-pass.banner.additional-details'"
										>For additional details and guidance please visit
										<a
											class="faq"
											(click)="showFaq($event)"
											[translate]="'vax-pass.banner.vaxstatus-faq'"
											[href]="vaxpassFAQUrl"
											>VaxStatus FAQ.</a
										></span
									>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="isFullyVaccinated">
						<app-vax-pass-card [profile]="profile"></app-vax-pass-card>
					</div>
				</ng-container>
				<app-immunization [profile]="profile"></app-immunization>
			</div>
		</div>
	</div>
</div>

<app-ehe-footer [socialNetwork]="true"></app-ehe-footer>
<app-ehe-spinner-hide></app-ehe-spinner-hide>
