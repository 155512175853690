import { Component, OnInit } from '@angular/core';
import { AnalyticsBean } from 'src/app/beans/analytics/analytics-module.bean';
import { FormControl } from '@angular/forms';
import { VaxPassService } from '../../../services/dashboard/vax-pass.service';
import { ActivatedRoute } from '@angular/router';
import { Environment } from '../../../config/environment';
import { WordPressOverlayComponent } from '../../shared/modals/word-press-overlay/word-press-overlay.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-vax-pass',
	templateUrl: './vax-pass.component.html',
	styleUrls: ['./vax-pass.component.scss'],
})
export class VaxPassComponent implements OnInit {
	/** analytics data bean */
	analyticsData: AnalyticsBean;
	vaccineConfirmation: FormControl = new FormControl();
	showGetMyVaccineBtn: boolean = false;
	showRadioButtons: boolean = false;
	isChecked: boolean;
	isPartiallyVaccinated = false;
	isFullyVaccinated = false;
	profile: any;
	loader = false;
	step2 = false;
	errorMessage = '';
	checkBackError = false;
	maxRetry = 3;
	attemptedRetries = 0;
	vaxpassEnabled = false;
	vaxpassFAQUrl = 'https://ehe.health/vaxstatusfaq/';

	constructor(
		private vaxPassService: VaxPassService,
		private activatedRoute: ActivatedRoute,
		private environment: Environment,
		private dialog: MatDialog
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'vax-pass';
		this.analyticsData.pageUrl = '/vaxstatus';

		// Hide vaxstatus onboarding form
		// if(this.environment.getEnvironment().VAXPASS_TOGGLE === "ON"){
		//   this.vaxpassEnabled = true;
		// }
	}

	ngOnInit() {
		this.loader = true;
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['profile']) {
				this.profile = Buffer.from(JSON.parse(params['profile']), 'base64');
				this.initializeProfile();
			} else {
				this.getCovidRecord();
			}
		});
	}

	onCancel() {
		this.vaccineConfirmation.setValue(false);
		this.isChecked = false;
		this.step2 = false;
		this.isFullyVaccinated = false;
		this.refreshProfile(true);
	}

	hideGetMyVaccineButton() {
		this.analyticsData.id = 'Get My Vaccine Passport';
		this.showGetMyVaccineBtn = false;
		this.showRadioButtons = true;
		this.checkBackError = false;
	}

	/* get user input - whether they're fully vaccinated or not */
	getVaccineConfirmation(event) {
		this.isChecked = event.source.checked;
		this.showRadioButtons = false;
	}

	refreshProfile(cancel = false) {
		this.analyticsData.id = 'Try Again';
		this.loader = true;
		this.isPartiallyVaccinated = false;
		this.checkBackError = false;
		this.errorMessage = '';
		setTimeout(() => {
			this.getCovidRecord(cancel);
		}, 2000);
	}

	getCovidRecord(cancel = false) {
		this.checkBackError = false;
		this.errorMessage = '';
		this.vaxPassService.getPatientCovidRecord().subscribe(
			response => {
				if (response.message === 'AWAITING_RESULTS') {
					if (this.attemptedRetries < this.maxRetry) {
						this.attemptedRetries += 1;
						this.loader = true;
						setTimeout(() => {
							this.refreshProfile(cancel);
						}, 10000);
					} else {
						this.loader = false;
						this.errorMessage =
							'The retrieval of your vaccination record is taking longer than expected. It might take a minute or two to complete.';
						this.checkBackError = true;
					}
				} else {
					this.profile = response;
					this.initializeProfile(cancel);
				}
			},
			err => {
				console.error(`[${VaxPassComponent.name}][${this.getCovidRecord.name}]`, err);
				let states = '';
				if (err.data && err.data.states) {
					const matches = err.data.states.match(/\[(.*?)\]/);
					if (matches) {
						states = matches[1];
					}
				}
				if (err.data && err.data.vaxpassStatus == '422') {
					this.errorMessage =
						'We are currently not able to access vaccination records in ' +
						states +
						". We are actively working to connect to the state's vaccination registry. Please check back in a week or so. Sorry for the inconvenience.";
				} else if (err.data && err.data.vaxpassStatus == '404') {
					this.errorMessage =
						'We were not able to locate your vaccination record in the state of ' +
						states +
						'. Please check your information and try again. If you continue to experience difficulties retrieving your vaccination record, there may be a discrepancy between the state’s vaccination registry and the information you have provided, or your vaccination event has not been processed yet. States vary in the time it takes to update their vaccination records. Please check back in a week or so. Sorry for the inconvenience.';
				} else {
					this.errorMessage = 'There was a problem retrieving your vaccination record. Please try again later.';
				}
				if (!this.isChecked) {
					this.showGetMyVaccineBtn = true;
				}
				this.checkBackError = true;
				this.loader = false;
			}
		);
	}

	initializeProfile(cancel = false) {
		if (this.profile.status === 'NOT_VACCINATED' || this.profile.message === 'RECORD_NOT_FOUND') {
			this.checkBackError = true;
			const state = this.profile.states.vaccineStates;
			if (!this.profile.eheAdministered) {
				this.errorMessage =
					'We located your record with the state of ' +
					state +
					', but it does not include a COVID-19 vaccination event. If your vaccination was recent, please check back in a few days. Sorry for the inconvenience.';
				this.showGetMyVaccineBtn = true;
			} else {
				this.showGetMyVaccineBtn = false;
				this.errorMessage =
					'We located your record but it does not include a COVID-19 vaccination event. If your vaccination was recent, please check back in a few days. Sorry for the inconvenience.';
			}
		} else if (!this.profile.consentAccepted && !this.profile.eheAdministered) {
			this.showGetMyVaccineBtn = true;
			// redirecting to step 2 if user logs back in after editing email
			this.activatedRoute.queryParams.subscribe(params => {
				if (!cancel && params['display'] === 'consent-form') {
					this.showGetMyVaccineBtn = false;
					this.step2 = true;
				}
			});
		} else {
			if (this.profile.status === 'FULLY_VACCINATED') {
				this.isFullyVaccinated = true;
			} else if (this.profile.status === 'PARTIALLY_VACCINATED') {
				if (!this.profile.eheAdministered) {
					this.isPartiallyVaccinated = true;
				} else {
					this.isFullyVaccinated = true;
				}
			} else {
				this.showGetMyVaccineBtn = true;
			}
		}
		this.loader = false;
	}

	showFaq(event?: MouseEvent) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: this.vaxpassFAQUrl },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}
}
