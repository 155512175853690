import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { Environment } from './app/config/environment';


if (window['stopAppLoad_IE']) {
	console.error('Sorry, the Internet Explorer browser is no longer supported!');
} else {
	console.info('environment.production?:', environment.production);

	if (environment.production) {        
		enableProdMode();  
	}
	platformBrowserDynamic()
		.bootstrapModule(AppModule).then(moduleRef => {
            const injector = moduleRef.injector;
            const configService = injector.get(Environment); // Get the ConfigService instance
            console.info('configService.ENV.enable_osano?:', configService.ENV.enable_osano);
            if(configService.ENV.enable_osano === 'true') {
                console.log = function () { };
                const s = document.createElement('script');        
                s.type = 'text/javascript';
                s.src = 'https://cmp.osano.com/AzqJMPTLxPd6b2C71/4160be0d-ece1-45c9-bdf7-8d6f04b2f04b/osano.js';
                document.getElementsByTagName('head')[0].appendChild(s);   
            }
            
          })
		.catch(err => console.log(err));
}

datadogRum.init({
    applicationId: '8a920592-5692-4e5c-89a6-3c7e6a27fdb2',
    clientToken: 'pub8e03b54ff3221f30ab365a71c449dbfa',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'myehe-ui',
    env: environment.envName,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
});